<template>
  <Breadcrumb />
</template>
<script>
export default {
  name: "DataSummary",
  mounted() {
    this.$router.push({ name: "PageUnderConstruction" });
  },
};
</script>
